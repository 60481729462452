@import "./variables";

*,
::before,
::after {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  outline: none;
}

a {
  color: initial;
  text-decoration: none;
}

body {
  background-color: white !important;
}

.main {
  min-height: calc(100vh - (472px - 100px));
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 800px,
  xl: 800px,
  xxl: 800px
);

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  width: fit-content;
  font-family: 'Noto Serif JP', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  text-align: center;
  position: relative;
  margin-top: 104px;
}

.heading {
  color: white;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 64px;
    border: 1px solid white;
  }
}

.heading.primary {
  color: $light-blue;

  &::before,
  &::after {
    border: 1px solid $light-blue;
  }
}

.heading::before {
  left: -80px;
}

.heading::after {
  right: -80px;
}

@media (max-width:600px) {
  .heading {
    padding: 0 10px;
    width: fit-content;
    font-size: 25px;

    &::before,
    &::after {
      top: auto;
      width: 100%;
    }
  }

  .heading::before {
    left: 0;
    top: -10px;
  }

  .heading::after {
    right: 0;
    bottom: -10px
  }

}


.loader-wrapper {
  min-height: calc(100vh - (472px - 100px));
  display: flex;
  justify-content: center;
  align-items: center;

  #page {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  #h3 {
    color: white;
  }
  
  #ring {
    width: 190px;
    height: 190px;
    border: 1px solid transparent;
    border-radius: 50%;
    position: absolute;
  
    &:nth-child(1) {
      border-bottom: 8px solid rgb(255, 141, 249);
      animation: rotate1 2s linear infinite;
    }
  
    &:nth-child(2) {
      border-bottom: 8px solid rgb(255, 65, 106);
      animation: rotate2 2s linear infinite;
    }
  
    &:nth-child(3) {
      border-bottom: 8px solid rgb(0, 255, 255);
      animation: rotate3 2s linear infinite;
    }
  
    &:nth-child(4) {
      border-bottom: 8px solid rgb(252, 183, 55);
      animation: rotate4 2s linear infinite;
    }
  }
  
  @keyframes rotate1 {
    from {
      transform: rotateX(50deg) rotateZ(110deg);
    }
  
    to {
      transform: rotateX(50deg) rotateZ(470deg);
    }
  }
  
  @keyframes rotate2 {
    from {
      transform: rotateX(20deg) rotateY(50deg) rotateZ(20deg);
    }
  
    to {
      transform: rotateX(20deg) rotateY(50deg) rotateZ(380deg);
    }
  }
  
  @keyframes rotate3 {
    from {
      transform: rotateX(40deg) rotateY(130deg) rotateZ(450deg);
    }
  
    to {
      transform: rotateX(40deg) rotateY(130deg) rotateZ(90deg);
    }
  }
  
  @keyframes rotate4 {
    from {
      transform: rotateX(70deg) rotateZ(270deg);
    }
  
    to {
      transform: rotateX(70deg) rotateZ(630deg);
    }
  }
  
  

}



@import "../../../node_modules/bootstrap/scss/bootstrap";