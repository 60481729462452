@import "../../assets/styles/_variables";

.btn-section-wrapper {
    display: none;
}

.navbar-link-wrapper-mobile {
    display: none;
}

.desktop-logo {
    display: block;
}

.mobile-logo {
    display: none;
}

header {
    background: $light-blue;
    min-height: 80px;

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            font-family: 'Noto Serif JP', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 43px;
            color: white;
        }

        .toggler {
            width: 40px;
            height: 40px;
            fill: #353E47;
            display: none;
        }

        .navbar-link-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;


            a {
                font-family: 'Noto Serif JP', serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
                color: white;

                &.active{
                    color: black;
                }

                &:not(:last-child) {
                    margin-left: 40px;
                    text-decoration: none;
                }
            }
        }
    }
}


@media (max-width:767px) {

    header.desktop {
        background-color: #EEF8FF;
        nav {
            .navbar-link-wrapper {
                display: none;
            }

            .toggler {
                display: inline-block;
            }
        }
    }

    .navbar-link-wrapper-mobile {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        margin: 0;
        background: linear-gradient(90deg, #07B3E3 27.2%, #4DC5E9 113.47%);
        display: flex;
        transform: translateX(100%);
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        z-index: 100;
        padding-right: 40px;
        padding-top: 15px;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;

        svg {
            fill: white;
            width: 40px;
            height: 40px;
            margin-bottom: 40px;
        }

        a {
            margin-bottom: 40px;
            color: white;
            text-decoration: none;
            font-family: 'Noto Serif JP', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 100%;
        }

        .btn-section-wrapper {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            .white-bg {
                margin-left: auto;
                width: 42px;
                height: 39px;
                background-repeat: no-repeat;
                background-size: contain;
                transform: matrix(0.99, -0.14, 0.16, 0.99, 0, 0);
                display: flex;
                justify-content: center;
                align-items: center;
        
                p {
                    font-size: 7px;
                    font-weight: 800;
                    color: white;
                    margin-right: 1px;
                    margin-bottom: 7px;
                }
            }
        }

        .btn-section {
            width: 255px;
            height: 53px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 10px 0px;
            margin-top: 0px;
            margin-bottom: 10px;

            .left {
                width: 80%;
                display: flex;
                align-items: center;
                padding: 10px 0px;

                .content {
                    margin-left: 16px;

                    p {
                        font-family: 'Noto Serif JP', serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 100%;
                        color: #00B900;
                    }

                    h4 {
                        font-family: 'Noto Serif JP', serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 100%;
                        color: #00B900;
                    }
                }
            }
        }
        .btn-section.email {
            .left {
                .content {
                    h4 {
                        color: rgba(7, 179, 227, 1);
                    }
                }
            }
        }
    }

    .navbar-link-wrapper-mobile.show {
        transform: none;
    }
}