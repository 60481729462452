@import "../../assets/styles/_variables";

footer {
    background: $light-grey;
    min-height: 392px;

    .footer-logo {
        font-family: 'Noto Serif JP', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 0.04em;
        color: $dark-grey;
        margin-top: 40px;
    }

    .content {
        width: 100%;
        max-width: 430px;
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            font-family: 'Noto Serif JP', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: $dark-grey;
        }

        ul {
            display: flex;
            justify-content: center;
            li {
                position: relative;
                padding: 0 12px;
                color: $light-blue;
                font-family: 'Noto Serif JP', serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 100%;
                a{
                    color: $light-blue;
                    text-decoration: none;
                    &.active{
                        color: black;
                    }
                }
            }

            li:nth-child(1) {
                padding-left: 0;
            }

            li:not(:last-child)::before {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 2px;
                height: 15px;
                border-right: 1px solid $light-blue;
                padding-right: 0;
            }
        }

        .copyright {
            margin-top: 44px;
            font-family: 'Noto Serif JP', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            color: $dark-grey;
        }
    }
}

@media (max-width:450px) {
    footer {
        .content {
            ul {
                li {
                    font-size: 11px;
                }
            }
        }
    }
}

@media (max-width:325px) {
    footer {
        .content {
            ul {
                li {
                    font-size: 8px;
                }
            }
        }
    }
}

@media (min-width:501) and (max-width:767px) {}

@media (min-width:786) and (max-width:1023px) {}

@media (min-width:1024) and (max-width:1200px) {}